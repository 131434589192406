.photoPreview {
  display: flex;
  align-items: center;
  margin: 0;

  .stub {

  }

  .photo {
    border-radius: 50%;
    width: 150px;
  }

  .actions {
    margin: 0 0 0 24px;
    //margin: 0 0 0 24px;
  }
  .avatar {
    width: 120px;
    height: 120px;
  }

}
