.descriptionContainer {
  position: relative;
  //margin: 0 20px 0 0;
  width: 100%;
  height: 100%;

  .description {
    //max-height: 74px;
    height: 116px;
    overflow-y: auto;

    background: #e5e5e5;
    border: 1px solid #CCD3D9;
    border-radius: 8px;
    padding: 8px;
  }

  .fieldName {
    position: absolute;
    top: -10px;
    left: 8px;
    color: #353535;
    opacity: 0.5;
  }
}

.link {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
}