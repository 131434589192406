.buttonsPlugin {
  height: 100%;
  padding: 4px 0;
  border-radius: 2px;

  .trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    padding: 0 8px;

    //margin-top: var(--ck-spacing-small);
    //margin-bottom: var(--ck-spacing-small);
    &:hover {
      background: var(--ck-color-button-default-hover-background);
    }
  }

  &.open .trigger {
    background: #c4c4c4;
  }

  .title {

  }

  .list {
    position: absolute;
    border-radius: 2px;
    background: #fff;
    box-shadow: var(--ck-drop-shadow),0 0;
    padding: 10px;
    //display: flex;
    width: 500px;
    //flex-wrap: wrap;

    .title {
      font-weight: bold;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      //flex-direction: column;

      > div {
        margin: 0 0 5px 0;
      }

      .button1,
      .button2,
      .button3 {
        border: 2px solid #B6303D;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 5px 19px;
        background: white;
        margin: 0 5px 0 0;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }
      }

      .button2 {
        background: #B6303D;
        border: 2px solid #B6303D;
        color: #fff;

        height: 26px;
        padding: 0 12px;

        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin: 0 3px 3px 0;
      }
    }

    .usualSearch {
      cursor: pointer;
    }

    .textWithContactButton {
      cursor: pointer;
    }

    //.item {
    //  width: 32px;
    //  height: 32px;
    //  cursor: pointer;
    //  margin: 2px;
    //
    //  img {
    //    width: 100%;
    //    height: 100%;
    //  }
    //}
  }
}

