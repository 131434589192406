.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.element {
  gap: 16px;
  width: 100%;
  padding: 16px 22px;
  background-color: #fafafa;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  &:hover {
    background-color: #d9d9d9;
  }
}
