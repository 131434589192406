.nameCell {
  display: flex;
  align-items: center;

  :global(.ant-avatar) {
    width: 50px !important;
    min-width: 50px !important;
  }

  img {
    width: 50px;
    height: 50px;
    object-fit: contain !important;
  }
}