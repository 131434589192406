.foldersGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 16px;
  column-gap: 24px;
  margin-bottom: 16px;
}

.addButtonsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  column-gap: 24px;
  margin-bottom: 16px;

  .button {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    padding: 16px 22px;
    height: fit-content;
  }

  &.folderSelected {
    grid-template-columns: repeat(1, 1fr);
  }
}

.folder {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: var(--primary-grey-5);
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-black);
  border-radius: 8px;
  padding: 12px 12px 12px 24px;

  &:hover {
    .moreButton {
      display: block;
    }
  }

  .nameContainer {
    gap: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .moreButton {
    display: none;
    width: 34px !important;
    height: 34px !important;
    border-radius: 8px !important;

    &:hover {
      background-color: var(--additional-grey-hover);
    }

    &:active {
      background-color: var(--additional-grey-pressed);
    }

    svg {
      color: var(--primary-grey-1);
    }
  }

  &:hover {
    background-color: var(--primary-grey-4) !important;
  }

  svg {
    color: var(--primary-grey-3);
  }
}

.prices {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  & > div {
    width: 100%;
  }

  .priceContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 16px 22px;
    background-color: white;
    border-radius: 8px;

    .price {
      font-size: 16px;
      font-weight: 600;
      margin-left: auto;
      white-space: nowrap;
    }

    .actionBtn {
      padding: 4px 8px;
    }
  }
}

.sectionTitleContainer {
  display: flex;
  align-items: center;

  span:nth-child(1),
  svg {
    color: var(--primary-grey-1);
  }

  span:nth-child(1) {
    cursor: pointer;

    &:hover {
      color: var(--primary-black);
    }

    &:active {
      color: var(--additional-black);
    }
  }

  .backIcon {
    transform: rotate(180deg);
    margin: 0 28px;
    flex-shrink: 0;
    height: 14px;
    width: 9px;
  }
}

.contextMenuIcon {
  width: 18px;
  height: 18px;
}
