@import '../colors.scss';

.node {
  background-color: white;
  padding: 15px 7px 5px 7px;
  width: auto;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1ch;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &.main {
    background-color: black;
    border-color: rgba(255, 255, 255, 0.5);
  }

  &.highlighted {
    border-width: 2px;

    &.level-1 {
      border-color: $level-1;
    }

    &.level-2 {
      border-color: $level-2;
    }

    &.level-3 {
      border-color: $level-3;
    }
  }

  &.selected {
    border-color: black !important;

    &.main {
      border-color: white !important;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 20px;
    width: 30px;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 1000;
    line-height: 1;
    border-radius: 4px;
    color: #fff;
    font-size: 9px;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  &.main::before {
    background: black;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.handle {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 0;
  transform: none;
  border: none;
  background-color: transparent;

  &.disabled {
    pointer-events: none;
  }
}

.iconContainer {
  display: flex;

  &.main {
    color: white;
  }
}

.input {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  font-family: inherit;
  min-height: 1ch;
  min-width: 30px;
  field-sizing: content;
  resize: none;
  overflow: hidden;
  max-width: 200px;
  text-align: center;

  &:read-only {
    pointer-events: none;
  }

  &.main {
    color: white;
  }
}
