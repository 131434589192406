.images {
  display: flex;
  flex-wrap: wrap;

  .card {
    margin: 10px;
    position: relative;

    .delete {
      position: absolute;
      top: 7px;
      right: 7px;
      cursor: pointer;
    }

    img {
      border-radius: 10px;
    }

    .checkboxes {
      margin: 8px 0 0 0;
    }
  }
}