.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  //max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);

  display: flex;

  & > .custom {
    display: flex;
    //width: 92px;
    width: 120px;
    background: var(--ck-color-toolbar-background);
    padding: 0 var(--ck-spacing-small);
    border: 1px solid var(--ck-color-toolbar-border);
  }

  & > .original {
    width: calc(100% - 120px);
  }
}

/* Adjust the look of the toolbar inside the container. */
.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: .342em;
  margin-bottom: .142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  //color: hsl( 203, 100%, 50% );
}

.document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: .171em;
  margin-bottom: .357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: .286em;
  margin-bottom: .952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: .5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc( 2 * var(--ck-spacing-large) );
  margin-right: calc( 2 * var(--ck-spacing-large) );
}

.document-editor {
  background: white;

  .ck-content {
    min-height: 400px;

    ul, ol {
      margin-left: 40px;
    }
  }

  &.error {
    border: 1px solid #d14343;
  }
}

.document-editor__editable {
  max-height: calc(100vh - 200px);
}

.document-editor .ck-content {
  h1, h2, h3 {
    display: flex;
    align-items: center;

    img {
      margin: 0 8px 0 0;
    }
  }
}

//.text-editor-wrapper {
//  width: 100%;
//  display: block;
//  margin-bottom: 25px;
//  height: 400px;
//
//  .text-editor {
//    height: calc(100% - 55px);
//    border: 1px solid #e6e8f0;
//    padding: 7.5px;
//    border-radius: 2px;
//
//    &.error {
//      border: 1px solid #d14343;
//    }
//  }
//}

