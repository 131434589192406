.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.siteLayoutSubHeaderBackground {
  background: #fff !important;
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  //background: #141414;

  .leftActions {
    padding: 0 16px;
  }
}

.logo {
  height: 40px;
  margin: 16px;
  //background: rgba(255, 255, 255, 0.3);
  color: white;

  //.hidden {
  //  opacity: 0;
  //  display: none;
  //  transition: opacity 0.5s linear;
  //  -webkit-transition: opacity 0.5s linear;
  //  -moz-transition: opacity 0.5s linear;
  //  -o-transition: opacity 0.5s linear;
  //  -ms-transition: opacity 0.5s linear;
  //}

  img {
    width: 100%;
    height: 100%;
  }
}

.layout {
  min-height: 100vh;
  height: 100%;

  .mainContent {
    padding: 0 16px;
    overflow-y: auto;
  }
}

:global(.ant-layout-sider) {
  max-height: 100vh;
  overflow: hidden;
}

:global(.ant-layout-sider-children) {
  overflow-y: auto;
}
