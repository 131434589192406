.iconsPlugin {
  height: 100%;
  padding: 4px 0;
  border-radius: 2px;
  //margin-top: var(--ck-spacing-small);
  //margin-bottom: var(--ck-spacing-small);


  .trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    padding: 0 8px;

    //margin-top: var(--ck-spacing-small);
    //margin-bottom: var(--ck-spacing-small);
    &:hover {
      background: var(--ck-color-button-default-hover-background);
    }
  }

  &.open .trigger {
    background: #c4c4c4;
  }

  .icons {
    position: absolute;
    border-radius: 2px;
    background: #fff;
    box-shadow: var(--ck-drop-shadow), 0 0;
    padding: 10px;
    display: flex;
    width: 128px;
    flex-wrap: wrap;

    .item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      cursor: pointer;
      margin: 2px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

