.photoPreview {
  display: flex;
  align-items: self-start;
  margin: 24px 0 12px 0;
  flex-direction: column;

  .photoBlock {
    display: flex;
    align-items: center;

    //.photo {
    //  border-radius: 50%;
    //  width: 150px;
    //}

    .actions {
      margin: 0 0 0 24px;
    }
  }

  .error {
    margin: 12px 0 0 0;
    color: #D14343;
    font-size: 0.75rem;
  }
}