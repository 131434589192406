.formContainer {
  margin: 40px 0 0 0;
  padding: 40px;
  background: #FFFFFF;
  border-radius: 8px;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
  }

  .titleDescription {
    margin: 8px 0 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
}