$heightSizes: (
  small: 28px,
  medium: 28px,
  large: 32px,
  big: 40px,
);

$fontSizeSizes: (
  small: 12px,
  medium: 13px,
  large: 14px,
  big: 16px,
);

.loader {
  position: relative;
  width: 100%;
  height: 100%;

  .spinnerContainer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .spinner {
      border: 4px solid #ebedf3;
      border-top: 4px solid #171582;
      border-radius: 50%;

      animation: spin 2s linear infinite;
      display: flex;
      align-self: center;
      top: 45%;
    }

    .content {
      margin: 7px 0 0 0;
    }
  }

  .container {
    position: relative;
    transition: opacity .2s;
    height: 100%;

    &.blur {
      //clear: both;
      //overflow: hidden;
      opacity: .6;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: none;
    }
  }
}

.loader.medium .spinnerContainer .spinner {
  width: 40px;
  height: 40px;
}

.loader.small .spinnerContainer .spinner {
  width: 26px;
  height: 26px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

