.authLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  //background-image: url(./../../../public/login-bg2.jpg), linear-gradient(135deg, #CCD3D9, #FFFFFF);
  background: linear-gradient(135deg, #CCD3D9, #FFFFFF);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .main {
    display: flex;
    flex-direction: column;

    width: 440px;
    margin: 110px 0 0 0;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 300px;
        height: 72px;
        margin-left: -88px;

        //path {
        //  fill: white;
        //}

        :global(.letter) {
          display: none;
        }
      }
    }
  }

  .footer {
    color: #fff;
    margin: 24px 0;
  }
}