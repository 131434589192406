.photoPreview {
  display: flex;
  align-items: center;
  margin: 24px 0 0 0;


  .avatar {
    width: 120px;
    height: 120px;
  }

  .stub {

  }

  .photo {
    border-radius: 50%;
    width: 150px;
  }

  .actions {
    margin: 0 0 0 24px;
  }
}

.cropperContainer {

  :global(.cropper-face) {
    background-color:inherit !important;
  }

  /* The css styles for `outline` do not follow `border-radius` on iOS/Safari (#979). */
  :global(.cropper-view-box) {
    box-shadow: 0 0 0 1px #39f;
    border-radius: 50%;
    outline: 0;
  }
}