.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.priceContainer {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 20px;
  margin-top: 12px;
}

.dropdown {
  width: 162px !important;
}

.dropdownOptions {
  z-index: 220000 !important;
}

.upgradeButton {
  width: 130px;
}

.itemContainer {
  display: flex;
  gap: 8px;
  align-items: center;

  .flag {
    border-radius: 2px;
  }
}
