.input {
  margin-bottom: 25px;
  width: 100%;
}

.chart {
  height: 250px;
  display: flex;
  gap: 10px;

  .bar {
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, #262626 var(--percent), transparent var(--percent));
  }
}
