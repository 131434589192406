.slider {
  display: flex;
  gap: 15px;
  overflow-x: scroll;
  margin: 50px 25px;
}

.tag {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: fit-content;
  align-items: center;
  padding: 7px 10px;

  span {
    margin: 0 !important;
  }
}