.searchContainer {
  background-color: white;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding-top: 10px;
  gap: 10px;
}

.cell {
  display: grid;
  place-items: center;
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  padding: 0;

  &.selected {
    border-color: #1677ff;
    color: #1677ff;
  }
}
