@import '../colors.scss';

.highlighted {
  stroke-width: 5;

  &.level-1 {
    stroke: $level-1;
  }

  &.level-2 {
    stroke: $level-2;
  }

  &.level-3 {
    stroke: $level-3;
  }
}

.selected {
  stroke: black !important;
  stroke-width: 6;
}
