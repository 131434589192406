.loginForm {
  margin: 24px 0 0 0;

  .fields {
    :global(.ant-form-item) {
      margin: 0;
    }

    :global(.ant-form-item-row) {
      margin: 0 0 16px 0;

      :global(.ant-form-item-label) {
        padding: 0 0 4px;

        label {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    :global(.ant-form-item:last-child) {
      :global(.ant-form-item-row) {
        margin: 0 0 14px 0;
      }
    }
  }

  .resetPasswordLink {
    display: flex;
    justify-content: flex-end;
  }

  .submitBtn {
    margin: 30px 0 0 0;
    width: 100%;
  }
}