.root {
  display: flex;
  flex-direction: column;

  :global(.ant-table-cell.no-padding) {
    padding: 0;
  }
}



//.MuiDataGrid-cell a {
//  overflow: hidden;
//  text-overflow: ellipsis;
//  text-decoration: none;
//
//  &:visited {
//    color: rgb(0, 0, 238);
//  }
//}