.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px;
  min-height: 0;
  
  svg {
    height: 100%;
    width: 100%;
  }
}