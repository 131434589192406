.container {
  position: relative;

  :global(.ql-toolbar) {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }

  :global(.ql-dl) {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  :global(.ql-editor p),
  :global(.ql-editor ol),
  :global(.ql-editor ul),
  :global(.ql-editor pre),
  :global(.ql-editor blockquote),
  :global(.ql-editor h1),
  :global(.ql-editor h2),
  :global(.ql-editor h3),
  :global(.ql-editor h4),
  :global(.ql-editor h5),
  :global(.ql-editor h6) {
    margin: 16px 0 !important;
  }
}

.editor {
  background: white;
}