.photoPreview {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;

  .avatar {
    width: 187px;
    height: 110px;
  }

  .stub {

  }

  //.photo {
  //  border-radius: 50%;
  //  width: 150px;
  //}

  .actions {
    margin: 0 0 0 24px;
  }
}
