.skeleton {
  background-color: rgb(204, 211, 217, .15);
  border-radius: 8px;
}

.itemContainer {
  position: relative;
  width: 100%;

  &[draggable='true'] {
    cursor: grab;
  }

  &.slotAvailable {
    * {
      pointer-events: none;
    }
  }

  &.slotBefore {
    &.vertical {
      &::before {
        content: '';
        position: absolute;
        top: -10px;
        right: 0;
        left: 0;
        height: 5px;
        border-radius: 2.5px;
        background-color: #1677ff;
      }
    }

    &.horizontal {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10px;
        width: 5px;
        border-radius: 2.5px;
        background-color: #1677ff;
      }
    }
  }

  &.slotAfter {
    &.vertical {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: -10px;
        left: 0;
        height: 5px;
        border-radius: 2.5px;
        background-color: #1677ff;
      }
    }

    &.horizontal {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -10px;
        bottom: 0;
        width: 5px;
        border-radius: 2.5px;
        background-color: #1677ff;
      }
    }
  }

  &.beingDragged > * {
    opacity: 0;
  }
}
