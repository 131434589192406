.images {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

}
  .card {
    flex-basis: calc((100% - (4 * 16px)) / 5);
  }

  .imagesWrapper {
    position: relative;

    img {
      cursor: pointer;
      aspect-ratio: 16/9;
      width: 100%;
      height: auto;
      object-fit: contain;
      background-color: #000;
    }
  }
