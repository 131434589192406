.folder {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: white;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  padding: 12px 12px 12px 24px;

  &:hover {
    .moreButton {
      display: block;
    }
  }

  .nameContainer {
    gap: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      flex-shrink: 0;
    }

    p {
      margin: 0;
      padding: 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }

  .moreButton {
    display: none;
    width: 34px !important;
    height: 34px !important;
    border-radius: 8px !important;
  }

  &:hover {
    background-color: #d9d9d9 !important;
  }
}
