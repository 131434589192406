.wrapper {
  position: relative;
  flex-grow: 1;
  background-color: white;
}

.container {
  position: absolute;
  inset: 0;
}

.savingIndicator {
  position: absolute;
  right: 10px;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  height: 50px;
  width: 50px;
  display: grid;
  place-items: center;
  color: white;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  border: 0;
}

.edgeTypeBtn {
  font-size: 16px;
  font-weight: 700;

  &.active {
    background-color: #1877f2;
    color: white;
  }
}

.highlightLevelsBtn:disabled {
  opacity: 0.5;
}

.loadingIndicator {
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.15);
}
