.tag {
  padding: 0 6px;
  line-height: 20px;
  border-radius: 4px;
  width: fit-content;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.expandable-row {
  margin: -8px 8px 0;
  padding: 0 16px;
  background-color: #F0F3FA;
  border: 1px solid #DFE1F3;
}

.iconButton {
  width: 24px;
  height: 24px;
  margin: auto;
  cursor: pointer;
}

.group {
  background: #EFF0F1;
  border-radius: 4px;
  padding: 0px 6px; 
}
